<template>
  <div
    class="outer tw-flex tw-flex-col tw-border-opacity-30 tw-rounded-lg tw-mb-4"
    :class="[
      containerClassess,
      {
        'tw-w-full': inline,
        'tw-border tw-shadow-sm': !inline
      }
    ]"
  >
    <div v-if="completed" class="tw-text-success">Purchased</div>
    <div
      class="top tw-p-3 tw-grid tw-gap-4 tw-grid-cols-1 "
      v-bind:class="{ 'md:tw-grid-cols-2': nights_in_dest }"
    >
      <div class="tw-text-left">
        <flight-direction
          title="DEPARTURE"
          :departureTime="departureTime"
          :departureDate="departureDate"
          :arrivalTime="arrivalTime"
          :arrivalDate="arrivalDate"
          :cityFrom="city_from"
          :airportFrom="airport_from"
          :airportFromCode="airport_from_code"
          :cityTo="city_to"
          :airportTo="airport_to"
          :airportToCode="airport_to_code"
          :flyDuration="durationFromSec(duration.departure)"
          :departureAirlines="departureAirlines"
        />
      </div>
      <div class="tw-text-left" v-if="nights_in_dest">
        <flight-direction
          title="RETURN"
          :departureTime="returnDepartureTime"
          :departureDate="returnDepartureDate"
          :arrivalTime="returnArrivalTime"
          :arrivalDate="returnArrivalDate"
          :cityFrom="returnCityFrom"
          :airportFrom="returnAirportFrom"
          :airportFromCode="returnAirportFromCode"
          :cityTo="returnCityTo"
          :airportTo="returnAirportTo"
          :airportToCode="returnAirportToCode"
          :flyDuration="durationFromSec(duration.return)"
          :departureAirlines="returnAirlines"
        />
      </div>
    </div>
    <div class="lower tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
      <div class="tw-flex tw-items-center">
        <div class="chip" v-if="nights_in_dest">
          {{ nights_in_dest }} {{ nights_in_dest > 1 ? 'nights' : 'night' }} in
          {{ city_to }}
        </div>
        <div class="chip">
          {{ seats }} {{ seats > 1 ? 'seats' : 'seat' }} available
        </div>
      </div>
      <div class="tw-flex tw-items-center">
        <div class="price">€{{ racePrice.toFixed(2) }}</div>
        <PrimaryButton
          v-if="!inline"
          class="bookNow tw-ml-4"
          v-on:click="
            () => {
              scrollTop();
              $emit('add-to-cart');
              nextTab();
            }
          "
          >Book Now</PrimaryButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { airlineSrc } from '@/store/flights/flightsUtils';
import PrimaryButton from '@/views/components/PrimaryButton.vue';
import moment from 'moment';
import FlightDirection from './FlightDirection.vue';
import { APP_CURRENCY } from '@/assets/constants/appConfig';

export default {
  components: {
    FlightDirection,
    PrimaryButton
  },
  props: {
    departure_at: String,
    arrival_at: String,
    city_from: String,
    airport_from: String,
    airport_from_code: String,
    city_to: String,
    city_code_to: String,
    airport_to: String,
    airport_to_code: String,
    itinerary: Array,
    duration: Object,
    availability: Object,
    nights_in_dest: Number,
    return_duration: String,
    price: Array,
    selected: Boolean,
    selectionBehavior: Boolean,
    cartBehavior: Boolean,
    inCart: Boolean,
    completed: Boolean,
    nextTab: Function,
    inline: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formValid: false,
    APP_CURRENCY
  }),
  computed: {
    departureDate() {
      return this.getDate(this.departure_at);
    },
    departureTime() {
      return this.getTime(this.departure_at);
    },
    arrivalTime() {
      return this.getTime(this.arrival_at);
    },
    arrivalDate() {
      return this.getDate(this.arrival_at);
    },
    returnRouteIndex() {
      return this.itinerary.findIndex(
        route => route.city_code_from === this.city_code_to
      );
    },
    returnRoute() {
      return (
        (this.returnRouteIndex >= 0 && this.itinerary[this.returnRouteIndex]) ||
        null
      );
    },
    returnRouteArrival() {
      return this.itinerary.length
        ? this.itinerary[this.itinerary.length - 1]
        : null;
    },
    returnDepartureDate() {
      return this.getDate(this.returnRoute?.departure_at);
    },
    returnDepartureTime() {
      return this.getTime(this.returnRoute?.departure_at);
    },
    returnArrivalDate() {
      return this.getDate(this.returnRouteArrival?.arrival_at);
    },
    returnArrivalTime() {
      return this.getTime(this.returnRouteArrival?.arrival_at);
    },
    returnCityFrom() {
      return this.returnRoute?.city_from;
    },
    returnAirportFrom() {
      return this.returnRoute?.airport_from;
    },
    returnAirportFromCode() {
      return this.returnRoute?.airport_from_code;
    },
    returnCityTo() {
      return this.returnRouteArrival?.city_to;
    },
    returnAirportTo() {
      return this.returnRouteArrival?.airport_to;
    },
    returnAirportToCode() {
      return this.returnRouteArrival?.airport_to_code;
    },
    departureAirlines() {
      return this.itinerary
        .slice(0, this.returnRouteIndex)
        .map(route => airlineSrc(route.airline));
    },
    returnAirlines() {
      return this.itinerary
        .slice(this.returnRouteIndex, this.itinerary.length)
        .map(route => airlineSrc(route.airline));
    },
    seats() {
      return this.availability.seats;
    },
    racePrice() {
      return this.price.length && this.price[0].price;
    },
    currency() {
      return this.price.length && this.price[0].currency;
    },
    containerClassess() {
      let classes = '';
      if (this.selected) {
        classes += 'tw-border-opacity-100 tw-bg-primary tw-bg-opacity-5';
      }
      if (this.selectionBehavior) {
        classes += ' tw-cursor-pointer';
      }
      if (this.completed) {
        classes +=
          ' tw-border-opacity-100 tw-border-success tw-bg-success tw-bg-opacity-5';
      }
      return classes;
    }
  },
  methods: {
    airlineImg(code) {
      return airlineSrc(code);
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    getDate(strDate) {
      return moment(new Date(strDate)).format('ddd MMM D');
    },
    getTime(strDate) {
      return moment(new Date(strDate)).format('h:mm A');
    },
    durationFromSec(seconds) {
      seconds = Number(seconds);
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor(seconds % (3600 * 24) / 3600);
      var m = Math.floor(seconds % 3600 / 60);
      var dDisplay = d > 0 ? h + 24 : '';
      var mDisplay = m > 0 ? m + 'm' : '';
      if (d > 0) {
        var hDisplaywD = h > 0 ? dDisplay + 'h' : '';
        return hDisplaywD + mDisplay;
      }
      else {
        var hDisplay = h > 0 ? h + 'h' : '';
        return hDisplay + mDisplay;
      }
    
    },
    trySelect() {
      !this.completed && this.$emit('select-flight');
    }
  }
};




</script>

<style scoped lang="scss">
@import '~@/assets/styles/variables.scss';
@import '~@/assets/styles/grid.scss';
.bookNow {
  border-radius: 90px;
  height: 36px !important;
  font-family: MontserratSemiBold !important;
  font-size: 14px !important;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow: none !important;
  color: #ffffff;
  transition: 0.3s;
}

.bookNow:hover {
  opacity: 0.9;
}
.bookNow:active {
  opacity: 0.5;
}

.outer {
  border: 1px solid #f1f1f1;
}

.lower {
  border-top: 1px solid #f1f1f1;
  padding: 24px;
}

.top {
  padding: 24px;
}

.chip {
  font-family: MontserratSemiBold;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #747378;
  background: #e4e4e6;
  padding: 10px 24px;
  margin-right: 12px;
  border-radius: 90px;
  height: 36px;
  line-height: 17px;
}

.price {
  font-family: MontserratSemiBold;
  font-size: 18px;
  line-height: 18px;
  color: #28262e;
}
</style>
