<template>
  <div
    class="tw-max-w-max
        tw-m-auto	
        tw-grid tw-grid-cols-1
        md:tw-grid-cols-1
        2xl:tw-grid-cols-2
        tw-gap-4
        md:tw-gap-8
        offers"
    v-if="offers && offers.length"
  >
    <div v-for="(offer, i) in offers" :key="`offer_item_${i}`">
      <express-offer-card
        v-if="offer.name"
        :id="experience.exp_id + '/' + type + '/' + i"
        :name="offer.name"
        :description="offer.description"
        :image="offer.image"
        :price="offer.price"
        :url="offer.url"
        :type="type"
        :item="offer"
        :location="location"
      />
    </div>
  </div>
  <div v-else>{{ emptyFieldText }}</div>
</template>
<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import ExpressOfferCard from './ExpressOfferCard.vue';

export default {
  components: {
    ExpressOfferCard
  },
  props: {
    offers: {
      //type: Array,
      default: () => []
    },
    experience: Object,
    emptyFieldText: String,
    type: String,
    location: String
  },
  data: function() {
    return {
      carouselSettings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        touchThreshold: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },

  computed: {}
};
</script>
<style scoped>
.offers {
  padding-bottom: 56px;
}
</style>
