<template>
  <div>
    <!-- <h4 class="tw-text-sm tw-text-primary tw-text-opacity-60">{{title}}</h4> -->
    <div class="date">{{ departureDate }}</div>
    <div class="tw-flex">
      <div class="left tw-flex tw-flex-col tw-items-center">
        <v-icon color="#EB95A9">mdi-circle-medium</v-icon>
        <div class="tw-flex-grow tw-relative tw-flex">
          <div class="flight-arrow tw-z-10 tw-flex tw-items-center">
            <PlaneIcon class="plane" />
          </div>
          <div class="path-line"></div>
        </div>
        <v-icon color="#EB95A9">mdi-circle-medium</v-icon>
      </div>
      <div>
        <div class="tw-flex tw-mb-4">
          <span class="time">{{ departureTime }} {{ cityFrom }}</span>
          <span class="airport">
            {{ airportFrom }} ({{ airportFromCode }})</span
          >
        </div>

        <div style="flex-flow: wrap" class="tw-flex tw-items-center">
          <div class="chip">
            {{ flyDuration }}
          </div>
          <div class="tw-flex">
            <template v-for="(airline, index) in departureAirlines">
              <img :key="`airline_${index}`" :src="airline" class="mr-2" />
            </template>
          </div>
          <div
            class="
              tw-rounded-full tw-w-max tw-px-5 tw-py-1 tw-text-sm tw-my-3
              mr-4
              tw-font-bold
              chip-grey
            "
          >
            {{
              departureAirlines.length === 0 || departureAirlines.length === 1
                ? 'Direct'
                : departureAirlines.length + ' stops'
            }}
          </div>
        </div>
        <div class="tw-flex tw-mt-4">
          <span class="time">{{ arrivalTime }} {{ cityTo }} </span>
          <span class="airport"> {{ airportTo }} ({{ airportToCode }})</span>
        </div>
      </div>
    </div>
    <div v-if="departureDate !== arrivalDate" class="tw-mt-4 date">
      {{ arrivalDate }}
    </div>
  </div>
</template>

<script>
import PlaneIcon from '@/assets/bookingPage/flights/plane.svg';

export default {
  components: {
    PlaneIcon,
  },
  props: {
    title: String,
    departureTime: String,
    departureDate: String,
    arrivalTime: String,
    arrivalDate: String,
    cityFrom: String,
    airportFrom: String,
    airportFromCode: String,
    cityTo: String,
    airportTo: String,
    airportToCode: String,
    cityCodeTo: String,
    flyDuration: String,
    departureAirlines: Array,
  },
};
</script>

<style lang="scss" scoped>
.path-line {
  content: '';
  position: absolute;
  left: calc(50% - 1px);
  width: 1px;
  border-right: 2px solid #ffe4ec;
  height: 100%;
}

.flight-arrow {
  width: 15px;
  .plane {
    background: white;
    box-shadow: 0 0 0 7px #ffffff;
  }
  &::before {
    background: white;
  }
}

.date {
  font-family: PoppinsSemiBold;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #28262e;
}

.chip {
  background: #ffe4ec;
  border-radius: 90px;
  font-family: MontserratSemiBold;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  color: #d62a53;
  margin-right: 12px;
}

.airport {
  font-family: MontserratMedium;

  font-size: 16px;
  line-height: 20px;
  display: inline-flex;
  align-items: center;

  color: #747378;
}

.time {
  font-family: MontserratSemiBold;
  margin-right: 4px;
  font-size: 16px;

  display: flex;
  align-items: center;

  color: #28262e;
}

img {
  border-radius: 50%;
}

.left {
  margin-right: 9.5px;
}

.chip-grey {
  color: #414145;
  background: #ececec;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  padding-bottom: 6px;
  padding-top: 6px;
}
</style>
