<template>
  <div>
    <Accommodations
      :city="city"
      v-if="seeOtherOptions"
      :destination="destination"
      :nextTab="nextTab"
    />
    <template v-else>
      <v-btn class="actionButton" @click="handleSeeOptions">
        See Other Options
      </v-btn>

      <div>
        <selected-hotel-info
          :hotel="getHotelPackage"
          @addToCard="addToCard"
          :checkin="checkInDate"
          :checkout="checkOutDate"
          :destination="destination"
          :childrenCount="childrenCount"
          :roomsCount="roomsCount"
          :adultsCount="adultsCount"
          :nextTab="nextTab"
        >
        </selected-hotel-info></div
    ></template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SelectedHotelInfo from './SelectedHotelInfo.vue';
import Accommodations from './Accommodations.vue';
//import moment from 'moment';
export default {
  data() {
    return {
      hotel: null,
      checkInDate: null,
      checkOutDate: null,
      destination: null,
      childrenCount: null,
      roomsCount: '1',
      adultsCount: null,
      seeOtherOptions: false
    };
  },
  props: [ 'city', 'nextTab' ],
  components: { Accommodations, SelectedHotelInfo },
  computed: {
    ...mapGetters([ 'getHotelPackage', 'accommodationsInCart' ]),
    ...mapGetters('search', [ 'from', 'to' ]),
  },
  mounted() {
    //const splitRateKey = this.getHotelPackage.rateKey.split('|');
    this.hotel = this.getHotelPackage;
    //this.checkInDate = moment(splitRateKey[0], 'YYYYMMDD').format('DD MMM YYYY');
    //this.checkOutDate = moment(splitRateKey[1], 'YYYYMMDD').format('DD MMM YYYY');
    this.checkInDate = this.from,
    this.checkOutDate = this.to,
    this.destination = this.getHotelPackage.destinationName;
    this.childrenCount = this.getHotelPackage.children;
    this.adultsCount = this.getHotelPackage.adults;
  },
  methods: {
    ...mapActions([
      'loadAvailabilityHotels',
      'loadExperiences',
      'addExperienceToCart',
      'roomsChecking'
    ]),
    handleSeeOptions() {
      this.seeOtherOptions = true;
    },
    async addToCard(hotelData) {
      if (!this.accommodationsInCart.length) {
        let hotel = hotelData;
        let rates;
        if (hotel.rateType === 'RECHECK') {
          rates = await this.roomsChecking({
            rooms: [ { rateKey: hotel.rateKey } ]
          });
        }
        if (rates) {
          hotel.net = rates.net;
          hotel.rateKey = rates.rateKey;
          hotel.rateType = rates.rateType;
          hotel.sellingRate = rates.sellingRate;
        }

        this.addExperienceToCart({
          id: hotel.rateKey,
          name: hotel.name,
          price: hotel.sellingRate ? hotel.sellingRate : hotel.net,
          image: hotel.image.path,
          quantity: 1,
          type: 'accommodation',
          details: {
            destination: this.destination,
            checkInDate: this.checkInDate,
            checkOutDate: this.checkOutDate,
            roomsCount: this.roomsCount,
            adultsCount: this.adultsCount,
            childrenCount: this.childrenCount,
            ...hotel
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn--is-elevated {
  box-shadow: none !important;
}

.actionButton {
  margin: auto;
  display: flex;
  border-radius: 190px !important;
  padding: 20px 40px !important;
  font-family: 'MontserratSemiBold';
  letter-spacing: -0.02rem;
  margin-bottom: 16px;
}
</style>
