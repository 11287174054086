<template>
  <div class="outer">
    <div class="hotel-top-images">
      <div>
        <ImageQualityContainer :src="hotel.images[0].path" />
        <ImageQualityContainer :src="hotel.images[1].path" />
        <ImageQualityContainer :src="hotel.images[2].path" />
      </div>
      <div>
        <ImageQualityContainer :src="hotel.images[3].path" />
        <ImageQualityContainer :src="hotel.images[4].path" />
        <ImageQualityContainer :src="hotel.images[5].path" />
        <ImageQualityContainer :src="hotel.images[6].path" />
        <span class="more-photos">
          <span @click="tab = 2" class="text"
            >+{{ hotel.images.length - 8 }} Photos</span
          >
          <ImageQualityContainer class="top-img" :src="hotel.images[7].path" />
        </span>
      </div>
    </div>
    <v-card class="hotel-details">
      <v-tabs v-model="tab">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
        <v-tab-item
          ><div class="tw-px-5">
            <div class="tw-mt-6">
              <v-card-title>
                <div class="tw-grid tw-grid-rows tw-w-full">
                  <div class="row-start-1 row-end-1">
                    <div class="tw-flex tw-flex-row tw-grid-cols-2">
                      <div class="tw-flex tw-flex-col tw-w-4/5">
                        <v-rating
                          :value="hotel.categoryName"
                          color="#F1BF42"
                          dense
                          half-increments
                          readonly
                          background-color="#DADCE0"
                          size="20"
                          empty-icon="$ratingFull"
                        ></v-rating>
                        <div
                          class="
                            label
                            tw-font-bold tw-text-4xl tw-p-0 tw-text-left
                          "
                        >
                          {{ hotel.name }}
                        </div>
                        <div class="tw-flex tw-mt-4">
                          <div
                            class="tw-text-sm tw-font-montserrat-medium address"
                          >
                            {{ hotel.address.content }}
                          </div>
                          <div class="tw-ml-6 tw-mt-1">
                            <review-rating :hotel="hotel" />
                          </div>
                        </div>
                        <div class="chips">
                          <span
                            v-for="surrounding in surroundings"
                            :key="surrounding.number"
                          >
                            <div
                              v-if="surrounding.facilityCode === 40"
                              class="chip-grey"
                            >
                              <div class="tw-flex">
                                <BeachPalm />
                              </div>
                              <template
                                v-if="surrounding.distance >= 1000"
                                class="tw-ml-auto"
                              >
                                {{ surrounding.distance / 1000 }}km
                              </template>
                              <template v-else class="tw-ml-auto">
                                {{ surrounding.distance }}m
                              </template>
                              from {{ surrounding.description }}
                            </div></span
                          >
                        </div>
                      </div>
                      <div class="tw-flex tw-flex-col-reverse">
                        <div class="tw-flex tw-items-end tw-flex-col">
                          <div class="room-name">{{ hotel.roomName }}</div>
                          <div class="cancellation">
                            {{
                              new Date(
                                hotel.cancellationPolicies[0].from
                              ).getDate() >= new Date().getDate()
                                ? 'Free cancellation'
                                : 'No refund available'
                            }}
                          </div>
                          <v-btn
                            class="add-room-btn tw-font-montserrat-semi-bold"
                            color="primary"
                            @click="
                              () => {
                                handleAddToCart(hotel);
                              }
                            "
                            ><CartIcon
                              class="tw-inline-block tw-h-4 tw-w-4 tw-mr-3"
                            />Add to cart</v-btn
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="tw-mt-8" />
                  <div class="row-start-2">
                    <div class="tw-flex tw-flex-row tw-grid-cols-2">
                      <div class="setwidth">
                        <hotel-description
                          :description="hotel.description.content"
                        ></hotel-description>
                      </div>
                      <div class="tw-float-right">
                        <div class="amenities tw-flex tw-flex-col">
                          <h3>Amenities</h3>
                          <amenities-icon
                            v-for="item in amenities"
                            :key="item.description"
                            :description="item.description"
                          >
                            <v-icon color="primary">
                              {{ item.icon }}
                            </v-icon>
                          </amenities-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-title>
            </div>
            <div class="tw-mx-5 tw-my-8">
              <GmapMap
                :center="{
                  lat: parseFloat(hotel.latitude),
                  lng: parseFloat(hotel.longitude),
                }"
                :zoom="19"
                style="width: 100%; height: 244px"
              >
                <GmapMarker
                  :position="{
                    lat: parseFloat(hotel.latitude),
                    lng: parseFloat(hotel.longitude),
                  }"
              /></GmapMap>
              <!-- <img :src="getImage('map.png')" alt="map" class="tw-w-full" /> -->
            </div>
            <hr class="tw-mx-5" />
            <!-- <modify-search
              :checkin="checkin"
              :checkout="checkout"
              :destination="destination"
              :childrenCount="childrenCount"
              :roomsCount="roomsCount"
              :adultsCount="adultsCount"
            ></modify-search> -->

            <slot></slot>

            <rooms-list
              :hideOptions="false"
              @close="
                () => {
                  $emit('close');
                }
              "
              :hotel="hotel"
              @addToCard="handleAddToCart"
            ></rooms-list>
          </div>
        </v-tab-item>
        <v-tab-item>
          <info
            :amenities="amenities"
            :hotel="hotel"
            :imgSrc="getImage('map.png')"
          />
        </v-tab-item>
        <v-tab-item>
          <photos :hotel="hotel" />
        </v-tab-item>
        <v-tab-item>
          <deals
            :hotel="hotel"
            @close="
              () => {
                $emit('close');
              }
            "
            @addToCard="handleAddToCart"
          />
        </v-tab-item>
        <v-tab-item>
          <surroundings :hotel="hotel" :surroundings="surroundings" />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ReviewRating from '@/views/inspiration/tab-items/components/ReviewRating.vue';
import HotelDescription from '@/views/inspiration/tab-items/components/HotelDescription.vue';
import AmenitiesIcon from '@/views/inspiration/tab-items/components/AmenitiesIcon.vue';
// import ModifySearch from './ModifySearch.vue';
import ImageQualityContainer from '@/views/inspiration/tab-items/components/ImageQualityContainer.vue';
import RoomsList from '@/views/inspiration/tab-items/components/RoomsList.vue';
import topAmenities from '@/assets/json/topAmenities.json';
import CartIcon from '@/assets/inspirationPage/cart.svg';
import BeachPalm from '@/assets/hotelDetails/beach-palm.svg';
import photos from '@/views/inspiration/tab-items/components/tab-items/photos.vue';
import info from '@/views/inspiration/tab-items/components/tab-items/info.vue';
import deals from '@/views/inspiration/tab-items/components/tab-items/deals.vue';
import surroundings from '@/views/inspiration/tab-items/components/tab-items/surroundings.vue';

export default {
  data: () => ({
    tab: null,
    items: [ 'Overview', 'Info', 'Photos', 'Deals', 'Surroundings' ],
  }),
  props: {
    hotel: Object,
    checkin: Date,
    checkout: Date,
    destination: String,
    childrenCount: Number,
    roomsCount: String || Number,
    adultsCount: Number || String,
    nextTab: Function,
  },
  emits: [ 'addToCard' ],
  components: {
    ImageQualityContainer,
    photos,
    ReviewRating,
    info,
    HotelDescription,
    AmenitiesIcon,
    RoomsList,
    CartIcon,
    // ModifySearch,
    deals,
    surroundings,
    BeachPalm,
  },
  computed: {
    surroundings: {
      get() {
        return this.hotel.facilities.filter(obj => {
          return obj.facilityGroupCode === 40;
        });
      },
    },
    amenities: function() {
      return this.hotel.facilities
        .filter(it => {
          return topAmenities.some(
            val =>
              val.facilityGroupName === it.facilityGroupName &&
              val.description === it.description
          );
        })
        .map(item => {
          const data = topAmenities.find(
            val =>
              val.facilityGroupName === item.facilityGroupName &&
              val.description === item.description
          );
          return { ...item, icon: data.icon };
        })
        .slice(0, 4);
    },
  },
  methods: {
    getImage: name => require('@/assets/inspirationPage/' + name),
    close() {
      this.$emit('close');
    },
    handleAddToCart(hotel) {
      this.scrollTop();
      this.$emit('addToCard', hotel);
      this.close();
      this.nextTab();
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
.chips {
  text-align: left;

  svg {
    transform: scale(0.75);
    margin: auto;
    margin-right: 8.73px;
  }
}
.chip-grey {
  margin-top: 24px;

  background: #f1f1f2;
  font-family: MontserratSemiBold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #747378;
  height: 28px;
  line-height: 28px;
  display: inline-flex;
  border-radius: 16px;
  padding: 0 16px;
  margin-right: 12px;
}

.v-tab {
  min-width: 20% !important;
  font-family: 'MontserratSemiBold';
  font-size: 1rem;
  letter-spacing: -0.03rem;
  text-transform: unset;
}

.app-modal {
  background: rgba(0, 0, 0, 0.8);
}

.label {
  font-family: 'PoppinsSemiBold';
  color: #28262e;
}
.v-rating {
  text-align: left;
}
.image-height {
  height: 400px;
  object-fit: cover;

  @media (min-width: 1090px) {
    max-height: 33.3vh;
  }
}

.address {
  color: #747378;
}

.outer {
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
}

.add-room-btn {
  height: 38px !important;
  background: #d62a53 !important;
  border-radius: 90px !important;
  box-shadow: none !important;
}

.setwidth {
  width: 70%;
}

.hotel-top-images {
  height: 548px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 70% 30%;
  padding: 6px;
  max-height: 45vh;

  @media (min-height: 915px) {
    grid-template-rows: auto 120px;
  }

  > div:nth-child(1) {
    display: grid;
    > div {
      border-radius: 8px;
    }
    grid-gap: 10px;
    grid-template-columns: 30% 70%;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    align-content: space-around;
    justify-content: space-between;
    margin: 10px;
    overflow: hidden;

    div:nth-child(1) {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
    }
    div:nth-child(3),
    div:nth-child(2) {
    }
  }

  > div:nth-child(2) {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: 100%;
    align-content: space-around;
    justify-content: space-between;
    padding: 0 10px;

    > div:nth-child(1),
    > div:nth-child(2),
    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5) {
      border-radius: 4px;
    }
  }

  // @media (max-height: 820px) {
  //   grid-template-rows: 100%;
  //   > div:nth-child(2) {
  //     display: none;
  //   }
  // }
  > div:nth-child(1),
  > div:nth-child(2),
  > div:nth-child(3) {
    border-radius: 8px;
  }
}

.more-photos {
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  .text {
    position: absolute;
    color: white;
    z-index: 999;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    border-radius: 4 px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }

  .top-img {
    border-radius: 4px;
    height: 100%;
  }
}

.amenities {
  h3 {
    font-family: PoppinsSemiBold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #28262e;
    text-align: left;
    margin-top: 32px;
  }
}

hr {
  border-color: #747378;
  margin-bottom: 32px;
  opacity: 0.1;
}

.modify-search {
  padding-left: 0;
  padding-right: 0;
}

.room-name {
  font-family: MontserratSemiBold;
  font-size: 0.59vw;
  letter-spacing: -0.02em;
  color: #28262e;
  text-align: right;
  height: 20px;
}
.cancellation {
  font-family: MontserratMedium;
  font-size: 12px;
  letter-spacing: -0.02em;

  color: #747378;
}
</style>
