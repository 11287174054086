<template>
  <div class="tw-flex tw-flex-col">
    <div class="flights-container">
      <search-flights :resetLazyload="resetLazyload" ref="search" />
      <sort-flights-component
        v-on:setDisplayFlights="setDisplayFlights($event)"
      />
      <div class="tw-flex tw-flex-col">
        <circle-loader :show="showCircleLoader"
          ><h2 class="tw-text-lg">
            Getting the best options based on your preferences...
          </h2></circle-loader
        >
        <template v-if="!!searchedFlights.length">
          <template
            v-for="(flight, index) in displayFlights.slice(
              0,
              1 + lazyLoadedFlights
            )"
          >
            <flights-item
              v-bind="flight"
              :key="`flight_${index}`"
              :nextTab="nextTab"
              cartBehavior
              @add-to-cart="addFlightToCartHandler(flight, index)"
              @select-flight="addFlightToCartHandler(flight, index)"
              :inCart="inCart(flight)"
              :loading="loadingFlight(index)"
            />
          </template>
          <mugen-scroll :handler="fetchData" :should-handle="!loading">
            loading...
          </mugen-scroll>
        </template>
        <template v-else-if="!showCircleLoader">
          <h2 class="tw-text-center tw-text-lg">
            No available flights for your search criteria. Please change your
            criteria.
          </h2>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SearchFlights from '../../../inspiration/tab-items/flights/components/SearchFlights';
import CircleLoader from '@/components/controls/containers/circleLoader/CircleLoader.vue';
import SortFlightsComponent from '../../../inspiration/tab-items/flights/components/SortFlightsComponent';
import FlightsItem from '../../../booking/steps/details/tabs/flights/steps/flightSelection/components/FlightsItem.vue';
import MugenScroll from 'vue-mugen-scroll';

export default {
  name: 'FlightsStep',
  components: {
    FlightsItem,
    SortFlightsComponent,
    SearchFlights,
    CircleLoader,
    MugenScroll,
  },
  data() {
    return {
      flights: null,
      loading: false,
      showCircleLoader: true,
      mode: 'BEST',
      lazyLoadedFlights: 0,
      loadingFlightIndex: -1,
    };
  },
  props: {
    nextTab: Function,
  },
  computed: {
    ...mapGetters([ 'experience', 'flightsInCart' ]),
    ...mapGetters('search', [ 'city', 'from', 'to' ]),
    ...mapGetters('flights', [ 'searchedFlights' ]),

    sortCheapestFlight() {
      return [ ...this.searchedFlights ].sort((flightA, flightB) => {
        if (flightA.price[0].price < flightB.price[0].price) {
          return -1;
        }
        if (flightA.price[0].price > flightB.price[0].price) {
          return 1;
        }
        return 0;
      });
    },
    sortFastestFlight() {
      return [ ...this.searchedFlights ].sort((flightA, flightB) => {
        if (flightA.duration.total < flightB.duration.total) {
          return -1;
        }
        if (flightA.duration.total > flightB.duration.total) {
          return 1;
        }
        return 0;
      });
    },
    sortEarliestTakeOff() {
      return [ ...this.searchedFlights ].sort((flightA, flightB) => {
        if (
          new Date(flightA.departure_at).getTime() <
          new Date(flightB.departure_at).getTime()
        ) {
          return -1;
        }
        if (
          new Date(flightA.departure_at).getTime() >
          new Date(flightB.departure_at).getTime()
        ) {
          return 1;
        }
        return 0;
      });
    },
    sortLatestTakeOff() {
      const latestTakeoff = [ ...this.sortEarliestTakeOff ];
      latestTakeoff.reverse();
      return latestTakeoff;
    },
    sortEarliestLanding() {
      return [ ...this.searchedFlights ].sort((flightA, flightB) => {
        if (
          new Date(flightA.arrival_at).getTime() <
          new Date(flightB.arrival_at).getTime()
        ) {
          return -1;
        }
        if (
          new Date(flightA.arrival_at).getTime() >
          new Date(flightB.arrival_at).getTime()
        ) {
          return 1;
        }
        return 0;
      });
    },
    sortLatestLanding() {
      const latestLanding = [ ...this.sortEarliestLanding ];
      latestLanding.reverse();
      return latestLanding;
    },
    displayFlights() {
      if (this.mode === 'FASTEST') {
        return this.sortFastestFlight;
      }
      if (this.mode === 'CHEAPEST') {
        return this.sortCheapestFlight;
      }
      if (this.mode === 'Earliest take-off') {
        return this.sortEarliestTakeOff;
      }
      if (this.mode === 'Earliest landing') {
        return this.sortEarliestLanding;
      }
      if (this.mode === 'Latest take-off') {
        return this.sortLatestTakeOff;
      }
      if (this.mode === 'Latest landing') {
        return this.sortLatestLanding;
      }
      return this.searchedFlights;
    },
  },
  mounted() {
    //this.loadFlightSuggestion();
    this.$refs.search.searchFlightsReq();
  },
  watch: {
    experience: {
      handler() {
        this.loadFlightSuggestion();
      },
      immediate: true,
    },
    city() {
      this.loadFlightSuggestion();
    },
    to() {
      this.loadFlightSuggestion();
    },
    from() {
      this.loadFlightSuggestion();
    },
    searchedFlights: {
      handler() {
        this.loading = false;
        this.showCircleLoader = false;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([ 'addFlightToCart' ]),
    ...mapActions('flights', [ 'searchFlights', 'setFlightRequest' ]),
    async loadFlightSuggestion() {
      if (!this.experience || !this.city || !this.from || !this.to) {
        return;
      }

      const payload = {
        fly_from: this.city,
        fly_to: this.experience?.flight_destination,
        departure_date: this.from,
        return_date: this.to,
        max_stopovers: 10,
        max_fly_duration: 50,
      };
      this.flights = await this.searchFlights(payload);
    },
    setDisplayFlights(criteria) {
      this.mode = criteria;
    },
    resetLazyload() {
      this.lazyLoadedFlights = 0;
      this.loading = false;
    },
    fetchData() {
      this.loading = true;
      if (this.displayFlights.length + 3 > this.lazyLoadedFlights) {
        this.lazyLoadedFlights += 3;
      }
      this.loading = false;
    },
    async addFlightToCartHandler(flight, index) {
      if (!this.inCart(flight)) {
        this.addFlightToCart({ flight, toggleDrawer: false });
        this.loadingFlightIndex = index;
        const res = await this.setFlightRequest(flight);
        this.loadingFlightIndex = -1;
        res && this.addFlightToCart({ flight, toggleDrawer: false });
      }
      // await this.$router.push({ path: '/book-trip/' + this.experience.exp_id + '/accommodation' });
    },
    inCart(flight) {
      return this.flightsInCart.some(
        item => item.booking_token === flight.booking_token
      );
    },
    loadingFlight(index) {
      return (
        this.loadingFlightIndex !== -1 && index === this.loadingFlightIndex
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.flights-container {
  max-width: 1640px;
}
</style>
