<template>
  <div class="offer-list-wrapper tw-px-12">
    <div>
      <div v-if="activeTab === 'flights'">
        <flights-tab-item
          :nextTab="nextTab"
          ref="flightsTabItem"
          :experience="experience"
        />
      </div>
      <div v-if="activeTab === 'activities'">
        <express-checkout-offers
          type="activities"
          :experience="experience"
          :location="location"
          :offers="activities"
          empty-field-text="Activities not found..."
        />
      </div>
      <div v-if="activeTab === 'accommodation'">
        <accommodation-step 
          :city="cities.data[this.experience.city_id]"
          :nextTab="nextTab"
          ref="accommodationStepItem"
          :experience="experience" 
          />
      </div>

      <div v-if="activeTab === 'restaurants'">
        <express-checkout-offers
          type="restaurants"
          :experience="experience"
          :offers="restaurants"
          key="restaurants"
          :location="location"
          emptyFieldText="Restaurants not found..."
        />
      </div>
    </div>
  </div>
</template>
<script>
import FlightsTabItem from '../steps/flights/FlightsStep.vue';
import ExpressCheckoutOffers from '../steps/thingstodo/ExpressCheckoutOffers.vue';
import AccommodationStep from '../steps/accommodation/AccommodationStep.vue';
import axios from 'axios';

export default {
  components: {
    ExpressCheckoutOffers,
    FlightsTabItem,
    AccommodationStep
  },
  props: {
    activeTab: String,
    experience: Object,
    nextTab: Function
  },
  data: function() {
    return {
      offers: [],
      cities: {}
    };
  },
  mounted() {
    (async() =>
      (this.cities = await axios.get(
        'https://travelo-91156-default-rtdb.europe-west1.firebasedatabase.app/destination_cities.json'
      )))();
  },

  computed: {
    location: {
      get() {
        try {
          const city = this.cities.data[this.experience.city_id];
          return city.name + ', ' + city.country;
        } catch (err) {
          return err;
        }
      }
    },
    activities() {
      return this.experience.activities;
    },
    restaurants() {
      return this.experience.restaurants;
    },
    hotels() {
      return this.experience.hotels;
    }
  }
};
</script>

<style scoped>
.offer-list-wrapper {
  position: relative;
  margin: auto;
  width: 100%;
}
</style>
