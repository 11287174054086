<template>
  <div class="tw-bg-white booking-container hidden-xs-only">
    <div class="inner tw-w-11/12 tw-flex tw-flex-col tw-m-auto">
      <div class="tw-flex tw-flex-row">
        <div class="steps-container tw-flex tw-flex-col">
          <express-checkout-steps />
          <router-view />
        </div>
        <express-checkout-basket />
      </div>
    </div>
  </div>
</template>

<script>
import ExpressCheckoutSteps from './components/ExpressCheckoutSteps.vue';
import ExpressCheckoutBasket from './components/ExpressCheckoutBasket.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  methods: {
    ...mapActions([ 'loadExperience' ])
  },
  components: {
    ExpressCheckoutBasket,
    ExpressCheckoutSteps
  },
  mounted() {
    this.loadExperience(this.$route.params.experienceId);
  },
  computed: {
    ...mapGetters([ 'getHotelPackage' ])
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
.booking-container {
  padding-top: 24px;
  min-height: 900px;

  .summary-block {
    padding: 10px;
  }

  .summary-block {
    background: rgba(255, 255, 255, 0.9);
    padding: 38px 46px;
  }

  .steps-block {
    border-radius: 10px;
  }
}

.inner {
  min-height: 100vh;
  @media (max-width: 1024px) {
    width: calc(100vw - 72px);
  }
  overflow: hidden;
}

.steps-container {
  width: 58.333333%;
  min-width: 58.333333%;
}
</style>
