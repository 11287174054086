var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outer tw-flex tw-flex-col tw-border-opacity-30 tw-rounded-lg tw-mb-4",class:[
    _vm.containerClassess,
    {
      'tw-w-full': _vm.inline,
      'tw-border tw-shadow-sm': !_vm.inline
    }
  ]},[(_vm.completed)?_c('div',{staticClass:"tw-text-success"},[_vm._v("Purchased")]):_vm._e(),_c('div',{staticClass:"top tw-p-3 tw-grid tw-gap-4 tw-grid-cols-1",class:{ 'md:tw-grid-cols-2': _vm.nights_in_dest }},[_c('div',{staticClass:"tw-text-left"},[_c('flight-direction',{attrs:{"title":"DEPARTURE","departureTime":_vm.departureTime,"departureDate":_vm.departureDate,"arrivalTime":_vm.arrivalTime,"arrivalDate":_vm.arrivalDate,"cityFrom":_vm.city_from,"airportFrom":_vm.airport_from,"airportFromCode":_vm.airport_from_code,"cityTo":_vm.city_to,"airportTo":_vm.airport_to,"airportToCode":_vm.airport_to_code,"flyDuration":_vm.durationFromSec(_vm.duration.departure),"departureAirlines":_vm.departureAirlines}})],1),(_vm.nights_in_dest)?_c('div',{staticClass:"tw-text-left"},[_c('flight-direction',{attrs:{"title":"RETURN","departureTime":_vm.returnDepartureTime,"departureDate":_vm.returnDepartureDate,"arrivalTime":_vm.returnArrivalTime,"arrivalDate":_vm.returnArrivalDate,"cityFrom":_vm.returnCityFrom,"airportFrom":_vm.returnAirportFrom,"airportFromCode":_vm.returnAirportFromCode,"cityTo":_vm.returnCityTo,"airportTo":_vm.returnAirportTo,"airportToCode":_vm.returnAirportToCode,"flyDuration":_vm.durationFromSec(_vm.duration.return),"departureAirlines":_vm.returnAirlines}})],1):_vm._e()]),_c('div',{staticClass:"lower tw-flex tw-flex-col md:tw-flex-row tw-justify-between"},[_c('div',{staticClass:"tw-flex tw-items-center"},[(_vm.nights_in_dest)?_c('div',{staticClass:"chip"},[_vm._v(" "+_vm._s(_vm.nights_in_dest)+" "+_vm._s(_vm.nights_in_dest > 1 ? 'nights' : 'night')+" in "+_vm._s(_vm.city_to)+" ")]):_vm._e(),_c('div',{staticClass:"chip"},[_vm._v(" "+_vm._s(_vm.seats)+" "+_vm._s(_vm.seats > 1 ? 'seats' : 'seat')+" available ")])]),_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"price"},[_vm._v("€"+_vm._s(_vm.racePrice.toFixed(2)))]),(!_vm.inline)?_c('PrimaryButton',{staticClass:"bookNow tw-ml-4",on:{"click":() => {
            _vm.scrollTop();
            _vm.$emit('add-to-cart');
            _vm.nextTab();
          }}},[_vm._v("Book Now")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }