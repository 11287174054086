<template>
  <div
    class="outer tw-flex tw-min-h-full tw-flex-col tw-flex-grow tw-bg-gray-100 tw-p-4"
  >
    <div class="tw-mt-16 tw-flex tw-flex-col tw-items-center tripBasket">
      <ShoppingBasket class="icon shoppingBasket" />
      <div class="tw-flex tw-flex-row">
        <div class="cart-title">
          My Trip Basket to 
        </div>
          <div
              class="tw-flex tw-flex-row tw-rounded-full tw-cursor-pointer tw-h-8 tw-ml-2 lg:tw-mt-4 md:tw-mt-2 sm:tw-mt-2"
            >
              <div class="location-pin tw-rounded-l-full">
                <Map-pin class="tw-w-4 tw-h-4 lg:tw-mt-0.5" />
              </div>
              <p class="location-padding tw-rounded-r-full">
                {{ this.city.name }} , {{ this.city.country }}
              </p>
            </div>
      </div>
    </div>
    <display-tabs></display-tabs>

    <div class="order-basket">
      <div class="order-header">
        Order Summary
      </div>
      <ul>
        <li v-for="(item, index) in totalSummary" :key="item.text">
          <div
            v-if="index == totalSummary.length - 1"
            class="total-divider"
          ></div>
          <div class="order-items">
            <div class="left" :class="{ 'total-price': item.bold }">
              {{ item.text }}
            </div>
            <div class="right" :class="{ 'total-price': item.bold }">
              €{{ roundToTwo(totalPrice) }}
            </div>
          </div>
        </li>
      </ul>
      <div class="action-bar">
        <v-btn
          class="cancel-btn actionButton"
          @click="
            $router.replace({
              name: 'LandingPage'
            })
          "
        >
          Cancel
        </v-btn>
        <FlatButton
          class="proceed-btn actionButton"
          @click="checkout"
          :disabled="totalCartCount <= 0"
        >
          <CartIcon class="tw-inline-block tw-h-4 tw-w-4 tw-mr-3" />
          Proceed to checkout
        </FlatButton>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
//import FlightDisplayLine from './FlightDisplayLine';
import CartIcon from '@/assets/inspirationPage/cart.svg';
import axios from 'axios';
import ShoppingBasket from '@/assets/shoppingCart/express-basket.svg';
import DisplayTabs from '@/views/shoppingCart/tabs/displayTabs.vue';
import FlatButton from '@/views/inspiration/FlatButton.vue';
import MapPin from '@/assets/inspirationPage/map-pin.svg';

export default {
  data() {
    return {
      drawer: false,
      totalSummary: [ { text: 'Sub total' }, { text: 'Total', bold: true } ],
      right: null,
      cities: [],
    };
  },
  components: {
    //FlightDisplayLine,
    ShoppingBasket,
    FlatButton,
    CartIcon,
    DisplayTabs,
    MapPin,
  },
  computed: {
    ...mapGetters([
      'experience',
      'flightsInCart',
      'totalPrice',
      'totalCartCount',
      'drawerState'
    ]),
    city: {
      get() {
        try {
          return this.cities.data[this.experience.city_id];
        } catch (err) {
          return null;
        }
      }
    },
  },
  async mounted() {
    this.cities = await axios.get(
      'https://travelo-91156-default-rtdb.europe-west1.firebasedatabase.app/destination_cities.json'
    );
  },
  methods: {
    ...mapActions([ 'toggleDrawer' ]),
    async checkout() {
      this.$router.push({ path: '/booking' });
    },
    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/variables.scss';
.tripBasket {
  @include respond-to(xs) {
    margin-top: 2rem;
  }
}
.icon {
  width: 64px;
  height: 58px;
  display: unset !important;
}
.shoppingBasket {
  @apply tw-text-black;
}
.cart-title {
  @apply tw-text-center tw-font-poppins-semi-bold tw-text-black tw-mb-8 tw-mt-4 ;
  letter-spacing: -2% I !important;
  font-size: 2vw;
}

.action-bar {
  align-items: flex-end;
  flex-grow: 0.5;
  width: 100%;
  justify-content: flex-end;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    button {
      width: 100%;
      margin: 16px 0 0 0;
    }
    .cancel-btn {
      background: transparent !important;
    }
  }
}
.order-basket {
  @apply tw-bg-white tw-rounded-3xl;

  background: #ffffff;
  border-radius: 30px;
  margin-left: 16px;
  margin-right: 16px;
  @media only screen and (min-width: 768px) {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.02);
  }

  margin-top: 32px;
}
.order-items {
  @apply tw-flex tw-flex-row;
}

.left {
  @apply tw-w-1/2;
  margin: 16px 0;

  font-family: MontserratMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.04em;

  /* Paragraph */

  color: #747378;
}
.right {
  @apply tw-w-1/2 tw-text-right;
  margin: 16px 0;
  font-family: MontserratMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.total-price {
  font-family: MontserratSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: -0.04em;

  color: #28262e;
}

.v-icon.v-icon {
  font-size: 15px !important;
}
.v-list-item {
  min-height: 24px;
}
.total-price {
  @apply tw-font-bold tw-text-sm;
}

.order-header {
  @apply tw-font-sans tw-text-left tw-text-gray-800 tw-text-xl tw-pt-2 tw-mt-2;

  padding: 24px;
  padding-bottom: 0;

  font-family: PoppinsSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;

  color: #28262e;
}

.action-bar {
  text-align: right;
  padding: 24px;
  padding-top: 8px;
  margin-bottom: 6px;
}

.cancel-btn,
.proceed-btn {
  border-radius: 104px;
  font-family: MontserratSemiBold;
  font-style: normal;
  font-size: 1vw;
  line-height: 18px;
  height: 54px !important;
  text-align: center;
  letter-spacing: -0.02em;

  /* Primary */
}

.cancel-btn {
  background: #ffe4ec !important;

  color: #d62a53 !important;
  border-radius: 104px !important;
  text-transform: none;
  margin: 0px;
  height: 54px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: none;
  margin-right: 16px !important;
}

.total-divider {
  background: #747378;
  opacity: 0.1;
  border-radius: 20px;
  height: 1px;
  width: 100%;
}

.order-basket > ul {
  padding-right: 24px;
}

.outer {
  box-sizing: content-box;
  max-width: 41.77777%;
  width: 41.77777%;
}


.location-pin {
  background-color: $secondary;
  padding-top: 6px;
  //padding-top: 8px;
  padding-left: 8px;
}

.location-padding {
  color: $primary;
  background-color: $secondary;
  padding: 10px 8px;
  @include respond-to(md) {
    padding: 12px 8px !important;
  }
  padding-right: 12px;
  font-size: 1vw;
}
</style>
