var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MatchMedia',[_c('div',{staticClass:"card text-left tw-border tw-shadow tw-rounded-xl md:tw-rounded",class:{
      restaurant: _vm.type === 'restaurants',
      activity: _vm.type !== 'restaurants'
    }},[_c('img',{staticClass:"offer-img tw-rounded-t-xl md:tw-rounded tw-cursor-pointer",attrs:{"src":_vm.src},on:{"click":function($event){_vm.showPopup = true}}}),_c('div',{staticClass:"tw-flex tw-flex-grow tw-p-6 tw-overflow-hidden"},[_c('div',{staticClass:"tw-flex-grow left"},[_c('div',{staticClass:"location"},[_c('PinSVG',{staticClass:"pin-svg"}),_vm._v(" "+_vm._s(_vm.location))],1),_c('h4',{staticClass:"tw-font-poppins tw-font-semibold tw-text-title tw-font-semibold tw-text-2xl tw-p-0 tw-pl-0 tw-text-left"},[_vm._v(" "+_vm._s(this.truncate(_vm.name, 15))+" ")]),_c('div',{staticClass:"tw-font-montserrat-regular tw-text-base tw-text-paragraph tw-leading-6 tw-mb-6 tw-mt-4 description"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('div',{staticClass:"tw-flex tw-flex-col tw-min-w-max w-24"},[_c('div',{staticClass:"tw-text-right tw-font-poppins tw-font-semibold tw-text-title tw-font-semibold tw-pl-4 tw-text-2xl tw-flex-grow tw-leading-8 tw-h-8"},[(_vm.type !== 'restaurants')?[_c('div',[_vm._v(_vm._s(_vm.priceWithCurrency))]),_c('div',{staticClass:"tw-text-right tw-font-montserrat-semi-bold tw-text-paragraph tw-font-medium tw-text-sm"},[_vm._v(" Per Adult ")])]:[(_vm.price < 10)?_c('div',[_vm._v(" €"),_c('span',{staticClass:"tw-opacity-50"},[_vm._v("€€€")])]):(_vm.price < 35)?_c('div',[_vm._v(" €€"),_c('span',{staticClass:"tw-opacity-50"},[_vm._v("€€")])]):(_vm.price < 85)?_c('div',[_vm._v(" €€€"),_c('span',{staticClass:"tw-opacity-50"},[_vm._v("€")])]):_c('div',[_vm._v("€€€€")])]],2),_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-2"},[(_vm.type !== 'restaurants')?_c('div',{staticClass:"add-to-cart tw-inline-flex",on:{"click":function($event){_vm.type === 'activities' || _vm.type === 'restaurants'
                ? (_vm.showModal = true)
                : _vm.addItemToCart()}}},[_c('CartIcon',{staticClass:"tw-inline-block tw-h-4 tw-w-4 tw-mr-3"}),_c('span',{staticClass:"tw-inline-block tw-h-4 tw-leading-4"},[_vm._v(_vm._s(_vm.btnText))])],1):_vm._e(),(_vm.type === 'restaurants')?_c('div',{staticClass:"reserve-now tw-h-4 tw-cursor-pointer tw-align-middle tw-text-primary tw-text-end",on:{"click":function($event){_vm.type === 'activities' || _vm.type === 'restaurants'
                ? (_vm.showModal = true)
                : _vm.addItemToCart()}}},[_c('CartIcon',{staticClass:"tw-inline-block tw-h-4 tw-w-4 tw-mr-3"}),_c('span',{staticClass:"tw-inline-block tw-h-4 tw-leading-4"},[_vm._v(_vm._s(_vm.btnText))])],1):_vm._e()])]),_c('transition',{attrs:{"name":"fade","appear":""}},[(_vm.showModal)?_c('div',{staticClass:"modal-overlay",on:{"click":function($event){_vm.showModal = false}}}):_vm._e()]),(_vm.showModal)?_c('date-modal',{attrs:{"item":_vm.item},on:{"close":_vm.closeModal,"update":_vm.updateModal}}):_vm._e()],1)]),(_vm.showPopup)?_c('IframePopup',{attrs:{"src":_vm.url},on:{"togglePopup":() => {
        _vm.showPopup = !_vm.showPopup;
      },"addItemToCart":function($event){_vm.type === 'activities' || _vm.type === 'restaurants'
                ? (_vm.showModal = true)
                : _vm.addItemToCart(); !_vm.showPopup}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }