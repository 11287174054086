
<template>
  <div class="">
    <div class="tw-flex tw-flex-row">
      <div
        class="tw-my-0 tw-mx-auto tw-table tw-mt-16 tw-font-poppins-semi-bold main-header"
      >
        Create your own trip from
      </div>
                  <div class="tw-flex tw-flex-col tw-mx-auto tw-mt-2" @click="tapToView">
                <img
                  class="influencer-image tw-cursor-pointer tw-mt-8"
                  :src="experience.influencer_data.image"
                />
                <div>
                  <div class="influencer-name tw-cursor-pointer tw-w-32 tw-ml-1">
                    {{ experience.influencer_data.name }}
                  </div>
                </div>
              </div>
    </div>
    <div
      class="tw-my-0 tw-mx-auto tw-table tw-mt-8 tw-font-montserrat-medium second-header"
    >
      Start building and booking your own trip by adding more trip components to
      your cart
    </div>
    <div
      class="nav-container tw-flex tw-flex-col sm:tw-flex-row tw-items-start tw-font-poppins-medium"
    >
      <div
        v-if="tabIndexMap.get(activeTab) > 0"
        class="chevron chevron-left"
        @click="
          () => {
            handleClickLeft();
          }
        "
      >
        <v-icon>mdi-chevron-left</v-icon>
      </div>
      <ul class="tab-list">
        <li
          class="tw-relative link tw-font-sans tw-text-sm tw-text-center"
          v-for="(tab, index) in availableTabs"
          :class="{ active: index <= tabIndexMap.get(activeTab) }"
          :key="tab.id"
        >
          <div class="divider" v-if="index > 0" />
          <div class="active-tab-divider-left" v-if="index > 0" />

          <button @click="activeTab = tab.id">
            <div
              class="
                      icon-container
                      tw-rounded-full tw-bg-secondary tw-h-12 tw-w-12 tw-mx-auto
                    "
            >
              <component
                v-if="index <= tabIndexMap.get(activeTab)"
                :is="tab.iconWhite"
              />
              <component v-else :is="tab.icon" />
            </div>
            <span class="label">{{ tab.label }}</span>
          </button>
        </li>
      </ul>
      <div
        v-if="tabIndexMap.get(activeTab) < 3"
        class="chevron chevron-right"
        @click="
          () => {
            handleClickRight();
          }
        "
      >
        <v-icon>mdi-chevron-right</v-icon>
      </div>
    </div>
    <div
      class="
              tw-flex tw-flex-col tw-w-full tw-mb-8
              md:tw-mb-0
              tw-mt-6
              md:tw-mt-0
            "
    >
      <express-checkout-offer-container
        :nextTab="nextTab"
        :active-tab="activeTab"
        :experience="experience"
        :influencerId="experience.influencer_data.influencer_id"
      />
    </div>
  </div>
</template>

<script>
import ShevronRightIcon from '@/assets/bookingPage/shevron-right.svg';
import { mapGetters, mapActions } from 'vuex';
import FlightIcon from '@/assets/inspirationPage/tabicons/plane.svg';
import AccommodationIcon from '@/assets/inspirationPage/tabicons/accommodation.svg';
import ActivitiesIcon from '@/assets/inspirationPage/tabicons/activities.svg';
import RestaurantIcon from '@/assets/inspirationPage/tabicons/restaurant.svg';
import FlightIconWhite from '@/assets/inspirationPage/tabicons/plane_w.svg';
import AccommodationIconWhite from '@/assets/inspirationPage/tabicons/accommodation_w.svg';
import ActivitiesIconWhite from '@/assets/inspirationPage/tabicons/activities_w.svg';
import RestaurantIconWhite from '@/assets/inspirationPage/tabicons/restaurant_w.svg';
import ExpressCheckoutOfferContainer from './ExpressCheckoutOfferContainer.vue';

export default {
  components: {
    ShevronRightIcon,
    FlightIcon,
    AccommodationIcon,
    ActivitiesIcon,
    RestaurantIcon,
    FlightIconWhite,
    AccommodationIconWhite,
    ActivitiesIconWhite,
    RestaurantIconWhite,
    ExpressCheckoutOfferContainer
  },
  data: () => ({
    tabIndexMap: new Map([
      [ 'flights', 0 ],
      [ 'accommodation', 1 ],
      [ 'activities', 2 ],
      [ 'restaurants', 3 ],
      [ 'events', 4 ]
    ]),
    availableTabs: [
      {
        id: 'flights',
        label: 'Flights',
        icon: FlightIcon,
        iconWhite: FlightIconWhite
      },
      {
        id: 'accommodation',
        label: 'Accommodation',
        icon: AccommodationIcon,
        iconWhite: AccommodationIconWhite
      },
      {
        id: 'activities',
        label: 'Activities',
        icon: ActivitiesIcon,
        iconWhite: ActivitiesIconWhite
      },
      {
        id: 'restaurants',
        label: 'Restaurants',
        icon: RestaurantIcon,
        iconWhite: RestaurantIconWhite
      }
    ],
    activeTab: 'flights',
    deactivateButtons: false
  }),
  computed: {
    ...mapGetters([ 'experience', 'cityaccommodation' ])
  },
  updated() {
    this.$nextTick(() => {
      this.deactivateButtons = false;
    });
  },
  methods: {
    ...mapActions([
      'loadExperience',
      'loadExperiences',
      'loadAvailabilityHotels'
    ]),
    setActiveTab(activeTab) {
      this.activeTab = activeTab;
    },
    handleClickLeft() {
      this.activeTab = this.availableTabs[
        this.tabIndexMap.get(this.activeTab) - 1
      ].id;
    },
    nextTab() {
      this.activeTab = this.availableTabs[
        this.tabIndexMap.get(this.activeTab) + 1
      ].id;
      console.log(this.activeTab);
    },
    handleClickRight() {
      this.nextTab();
    },
    tapToView() {
      this.$router.push({
        name: 'ExpressStoryView',
        params: {
          inspirationId: this.experience.exp_id,
        },
      });
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variables.scss';
@import '~@/assets/styles/grid.scss';

.main-header {
  font-size: 3.2vw;
  @include respond-to(md) {
    font-size: 2.8vw;
  }
}

.second-header {
  font-size: 1vw;
}

.label {
  margin-left: -100%;
  margin-right: -100%;
  position: relative;
  user-select: none;
}
.nav-container {
  max-width: 1640px;
  margin: auto;
  position: relative;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 32px;
}

.tab-list {
  @apply tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-0 tw-mx-auto;
  max-width: 444px;

  width: 100%;

  margin-bottom: 56px;
}

.chevron {
  flex: 0 0 5.3%;
  margin-bottom: auto;
  width: 48px;
  position: absolute;
  height: 48px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  transition: 0.3s;
  @media (max-width: 1200px) {
    display: none;
  }
}

.chevron-right {
  right: 3rem;
}

.chevron:hover > i {
  background: #f1f1f2bd;
}

.chevron > i {
  transition: 0.3s;
  width: 48px;
  color: #28262e;
  height: 48px;
  cursor: pointer;
  background: #f1f1f2;
  border-radius: 1.5rem;
}

.influencer-image {
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
}
.influencer-name {
  font-family: PoppinsMedium;
  font-size: 0.8vw;
  line-height: 1vw;
  letter-spacing: -0.04em;
  color: #28262e;

  mix-blend-mode: normal;
  margin-top: 1rem;
  margin-bottom: 1px;
}

.link {
  display: block;

  @include respond-to(xs-sm) {
    display: flex;
    align-items: center;
  }

  .icon-container {
    color: #d62a53;
    margin-bottom: 12px;
  }

  &.active {
    .active-tab-divider-left {
      right: calc(28px + 50%);
      height: 4px;
      background: #d62a53;
    }

    .active-tab-divider-left,
    .active-tab-divider-right {
      z-index: 1;
    }

    .icon-container {
      background: #d62a53;
      color: white;
    }
    .label {
      color: #d62a53;
    }
  }

  button {
    color: currentColor;
    width: 48px;

    svg {
      display: block;
      width: 20px;
      height: 48px;
      margin: auto auto;
    }
  }
}

.divider,
.active-tab-divider-left {
  width: 72px !important;
  height: 4px;

  border-radius: 30px;
  position: absolute;
  top: 24px;

  @media (max-width: 1180px) {
    margin: 0px calc(25% - 4px);
    width: calc(4vw + 8px) !important;
  }
}

.divider {
  border-top: 4px solid #ffe4ec;
  right: calc(28px + 50%);
  z-index: 0;
}

li {
  padding: 0;
}
</style>
